import './Sidebar.css';
import $ from 'jquery';

const Sidebar = () => {
    $(function () {
        $(".sub-btn").on("click", function () {
          $(this).next('.sub-menu').slideToggle();
          $(this).find('.dropdown').toggleClass('rotate');
        });
      });

    function openMenu() {
        const element = document.querySelector(".menu");
        element.classList.toggle("active");
      }

      function closeMenu() {
        const element = document.querySelector(".menu");
        element.classList.remove("active");
      }

    return (
        <div class="sidebar">
            <div class="menu-btn" onClick={openMenu}>
                <i class="fas fa-bars"></i>
            </div>
        
        <div class="menu">
            
          <div class="close-btn" onClick={openMenu}>
            <i class="fas fa-times"></i>
          </div>
          <div class="list">
            <div class="item"><a href="/wiki/commands">Comandos</a></div>
            <div class="item">
              <a class="sub-btn">Propriedades<i class="fas fa-angle-right dropdown"></i></a>
              <div class="sub-menu">
                <a href="/wiki/empresas" class="sub-item">Empresas</a>
                <a href="/wiki/transportadoras" class="sub-item">Transportadoras</a>
              </div>
            </div>
          </div>
        </div>
        </div>
    )
}

export default Sidebar;