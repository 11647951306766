import {gql} from '@apollo/client';
export const updateGuildCurrencyMutation = gql`
  mutation UpdateGuildCurrency($guildId: String!, $currency: String) {
      updateGuildCurrency(guildId: $guildId, currency: $currency, token: \"${window.localStorage.token}\") {
        currency
        guildId
      } 
    }
`;
export const updateLevelMutation = gql`
  mutation UpdateLevel($guildId: String!, $level: Boolean) {
      updateLevel(guildId: $guildId, level: $level, token: \"${window.localStorage.token}\") {
        level
        guildId
      } 
    }
`;
export const updateMusicMutation = gql`
  mutation UpdateMusic($guildId: String!, $music: Boolean) {
      updateMusic(guildId: $guildId, music: $music, token: \"${window.localStorage.token}\") {
        music
        guildId
      } 
    }
`;
export const updateEconomyMutation = gql`
  mutation UpdateEconomy($guildId: String!, $economy: Boolean) {
      updateEconomy(guildId: $guildId, economy: $economy, token: \"${window.localStorage.token}\") {
        economy
        guildId
      } 
    }
`;
export const updateDefaultRoleMutation = gql`
  mutation UpdateDefaultRole($guildId: String!, $defaultRole: String) {
      updateDefaultRole(guildId: $guildId, defaultRole: $defaultRole, token: \"${window.localStorage.token}\") {
        defaultRole
        guildId
      } 
    }
`;
export const updateWelcomeMessageMutation = gql`
  mutation UpdateWelcomeMessage($guildId: String!, $welcomeMessage: String, $welcomeChannel: String) {
    updateWelcomeMessage(guildId: $guildId, welcomeMessage: $welcomeMessage, welcomeChannel: $welcomeChannel, token: \"${window.localStorage.token}\") {
      guildId
      welcomeMessage
      welcomeChannel
    }
  }
`;

export const updateVerifySetupMutation = gql`
mutation UpdateVerifySetup($clientIp: String!, $failed: Boolean, $ipScore: Int) {
  updateVerifySetup(clientIp: $clientIp, failed: $failed, ipScore: $ipScore, token: \"${window.localStorage.token}\") {
    userId
    error
  }
}
`;

export const updateProfileBannerMutation = gql`
mutation UpdateProfileBanner($banner: String!) {
  updateProfileBanner(token: \"${window.localStorage.token}\", banner: $banner) {
    banner
  }
}
`;

export const updateProfileAboutMeMutation = gql`
mutation UpdateProfileAboutMe($aboutMe: String!) {
  updateProfileAboutMe(token: \"${window.localStorage.token}\", aboutMe: $aboutMe) {
    aboutMe
  }
}
`;

export const updateProfileMethodMutation = gql`
mutation UpdateProfileMethod($method: String!) {
  updateProfileMethod(token: \"${window.localStorage.token}\", method: $method) {
    method
  }
}
`;