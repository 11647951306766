import React from 'react';
import {MenuComponent, DashboardMenu} from '../../components'
//import {updateWelcomeMessage} from '../../utils/api';
import {useQuery, useMutation} from '@apollo/client'
import {configurePageQuery} from '../../graphql/queries';
import {updateGuildCurrencyMutation, updateLevelMutation, updateMusicMutation, updateEconomyMutation, updateDefaultRoleMutation, updateWelcomeMessageMutation} from '../../graphql/mutations';
export function ConfigurePage({
  history,
  match
}) {
  
  const { loading, error, data } = useQuery(configurePageQuery, {variables: {guildId: window.location.pathname.split("/")[2]}});
  const [updateCurrency] = useMutation(updateGuildCurrencyMutation)
  const [updateLevel] = useMutation(updateLevelMutation)
  const [updateMusic] = useMutation(updateMusicMutation)
  const [updateEconomy] = useMutation(updateEconomyMutation)
  const [updateDefaultRole] = useMutation(updateDefaultRoleMutation)
  const [updateWelcomeMessage] = useMutation(updateWelcomeMessageMutation)
  const login = () => window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord';
  
   const updateGuildCurrencyParent = async (currency) => {
      try {
       const response = await updateCurrency({
         variables: {
           guildId: window.location.pathname.split("/")[2],
           currency: currency.toString()
         }
       });
        console.log(response);
        return 200;
      } catch(err) {
        console.log(err)
        return err;
      }
    }
   
   const updateLevelParent = async (level) => {
      try {
        console.log(`${level}-level`)
        const response = await updateLevel({
         variables: {
           guildId: window.location.pathname.split("/")[2],
           level: level
         }
       });
        console.log(response);
      } catch(err) {
        console.log(err)
      }
    }
    
    const updateMusicParent = async (music) => {
      try {
        const response = await updateMusic({
         variables: {
           guildId: window.location.pathname.split("/")[2],
           music: music
         }
       });
        console.log(response);
      } catch(err) {
        console.log(err)
      }
    }
    
    const updateEconomyParent = async (economy) => {
      try {
        const response = await updateEconomy({
         variables: {
           guildId: window.location.pathname.split("/")[2],
           economy: economy
         }
       });
        console.log(response);
      } catch(err) {
        console.log(err)
      }
    }
    
    const updateDefaultRoleParent = async (roleId) => {
      try {
        console.log(roleId)
        if (roleId.defaultRole === "Nenhum") roleId.defaultRole = null;
       const response = await updateDefaultRole({
         variables: {
           guildId: window.location.pathname.split("/")[2],
           defaultRole: roleId.defaultRole,
         }
       });
        console.log(response);
        return 200;
      } catch(err) {
        console.log(err)
        return err;
      }
    }
    
    const updateWelcomeMessageParent = async (welcomeMessage) => {
      try {
      if (welcomeMessage.welcomeMessage == "Nenhuma") welcomeMessage.welcomeMessage = null
      else if (welcomeMessage.welcomeMessage) welcomeMessage.welcomeMessage == welcomeMessage.welcomeMessage.toString()
           if (welcomeMessage.welcomeChannel == "Nenhum") welcomeMessage.welcomeChannel = null
           else if (welcomeMessage.welcomeChannel) welcomeMessage.welcomeChannel == welcomeMessage.welcomeChannel.toString()
        console.log(welcomeMessage.welcomeMessage)
           console.log(welcomeMessage.welcomeChannel)
       const response = await updateWelcomeMessage({
         variables: {
           guildId: window.location.pathname.split("/")[2],
           welcomeMessage: welcomeMessage.welcomeMessage,
           welcomeChannel: welcomeMessage.welcomeChannel
         }
       });
        console.log(response);
        return 200;
      } catch(err) {
        console.log(err)
        return err;
      }
      //updateWelcomeMessage(window.location.pathname.split("/")[2], welcomeMessage, welcomeChannelId.welcomeChannel, window.localStorage.token);
    }
    if (error) console.log(error)//window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord'
    
    if (!loading && !error) {
      console.log(data)
      if (!data || !data.getUser || !data.getGuildConfig || !data.getGuildRoles || !data.getGuildChannels) window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord'
      if (!data.isInGuild) window.location.href = 'https://fusionbot.xyz/dashboard'
      else {
      const {
        getGuildConfig,
        getGuildRoles,
        getGuildChannels,
        getUser
      } = data;
      console.log(getUser)
      let guildInfo = data.isInGuild
      return (
        <div class="dashboard-main">
        <style>
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
</style>
<nav class="navigation-bar fixed">
<div class="left-side-entries">
<a href="https://fusionbot.xyz" style={{fontFamily: 'Pacifico', paddingTop:1}}>Fusion</a>
  <a href="https://fusiondiscord.vercel.app/dashboard" id="dashboard" style={{paddingTop:3}}><img src="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2Fgear.png?v=1595294274017" height={18} width={18} id="gear"></img> Dashboard</a>
  <a href="https://discord.gg/ryfTBmU" style={{paddingTop:4}}><img src="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2Fdiscord.png?v=1595296304827" height={18} width={18} id="discord"></img> Suporte</a>
  {/*<a href="https://twitter.com/fusion_discord"><img src="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2Ftwitter.png?v=1599094463069" height={18} width={18} id="twitter"></img> Twitter</a>*/}
</div>
<div class="right-side-entries">
{!loading && data && data.getUser ? <div><img src={`https://cdn.discordapp.com/avatars/${data.getUser.id}/${data.getUser.avatar}.png`} height={32} width={32}/><text class="login" style={{bottom: 6, position: 'relative'}}>{data.getUser.username}#{data.getUser.discriminator}</text></div> : <text onClick={login} style={{paddingTop:4}} class="login">Login</text>}
</div>
</nav>
         <h1 id="dashboard-title">{guildInfo.name
         }</h1>
         <div>
          <DashboardMenu
           user={getUser}
           guildInfo={guildInfo}
           config={getGuildConfig}
           roles={getGuildRoles}
           channels={getGuildChannels}
           updateCurrency={updateGuildCurrencyParent}
            updateLevel={updateLevelParent}
            updateMusic={updateMusicParent}
            updateEconomy={updateEconomyParent}
           updateRole={updateDefaultRoleParent}
            updateWelcome={updateWelcomeMessageParent}
           />
           </div>
           </div>
      )
    };
    } return <div id="loading"/>
}