import React from 'react';
import {Link} from 'react-router-dom';
import './style.css'
export function MenuComponent({
  guilds,
}) {
    return (
      <div class="right">
      {guilds.included.map((guild) => (
          <div class="guilds">
            {guild.icon ? <img src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`} height={56} width={56} id="icon"/>: null}
            <div id="guilds">
            {guild.icon ? <li id="li">{guild.name}</li> : <li>{guild.name}</li>}
            <i class="fa-solid fa-gear"></i> <Link to={ `/dashboard/${guild.id}` }>Configurar</Link>
            </div>
          </div>
        
      ))}
        {guilds.excluded.map((guild) => (
          <div class="guilds">
          <div id="guilds">
            <li>{guild.name}</li>
            <a href={'#'} target='_self' onClick={function pop() { window.open('https://discord.com/oauth2/authorize?=&client_id=606312049798152204&scope=bot&permissions=1345645695','pop',"height=590,width=450,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=auto,resizable=yes,copyhistory=no"); }}>Convidar o Fusion</a>
          </div>
          </div>
        ))}
      </div>
    )
}