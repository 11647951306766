import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { MenuPage, DashboardPage, CommandsPage, RedirectPage, TopggRedirectPage, AuthDiscord, ConfigurePage, WikiMain, WikiEmpresas, WikiTransportadoras, Buy0001, Buy0002, CoinsPage, BuyConfirmation, SetupPage, ProfilePage, Success} from "./pages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" exact={true} element={<MenuPage/>} />
              <Route path="/dashboard" exact={true} element={<DashboardPage/>} />
              <Route path="/wiki/commands" exact={true} element={<CommandsPage/>} />
              <Route path="/auth/discord/redirect/:url" exact={true} element={<RedirectPage/>} />
              <Route path="/topgg" exact={true} element={<TopggRedirectPage/>} />
              <Route path="/auth/discord/:url" exact={true} element={<AuthDiscord/>} />
              <Route path="/dashboard/:id" exact={true} element={<ConfigurePage/>} />
              <Route path="/wiki" exact={true} element={<WikiMain/>}/>
              <Route path='/wiki/empresas' exact={true} element={<WikiEmpresas/>} />
              <Route path='/wiki/transportadoras' exact={true} element={<WikiTransportadoras/>} />
              <Route path='/coins' exact={true} element={<CoinsPage/>}/>
              <Route path='/success' exact={true} element={<Success/>}/>
              <Route path='/buy/0001' exact={true} element={<Buy0001/>}/>
              <Route path='/buy/0002' exact={true} element={<Buy0002/>}/>
              <Route path='/buyconfirmation' exact={true} element={<BuyConfirmation/>}/>
              <Route path='/setup' exact={true} element={<SetupPage/>}/>
              <Route path='/profile' exact={true} element={<ProfilePage/>}/>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
