import React from 'react';
import axios from 'axios';
import './buy.css';
import { menuPageQuery } from '../../graphql/queries'
import {useQuery} from '@apollo/client';
export function Buy0002({
  history,
  match
}) {
    let Root = `https://${document.location.hostname}`;
    const { loading, error, data } = useQuery(menuPageQuery)
  
  if (error) window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord'
  if (!loading && !error) {
    if (!data.getUser) window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord'

    axios({
        method: 'POST',
        url: `${Root}/api/buy?id=002&userId=${data.getUser.id}`,
        headers: { 'content-type': 'application/json' }
      })
        .then(result => {
          if (result?.data?.url) window.location.href = result.data.url;
        })
      }

        return <body class="buy">
        <div id="loading"/>
    </body>
}