import React, { Fragment, useEffect } from 'react';
import './commands.css';
import axios from 'axios';
import Navbar from '../../../Navbar';
import Sidebar from '../../../Sidebar';
export function CommandsPage(props) {
  const [commands, setCommands] = React.useState(null);
  const [detailsShown, setDetailsShown] = React.useState([]);

  if (!commands) axios.get('https://fusionbot.discloud.app/commands').then(response => {
        setCommands(response.data.commands.map(command => command.config));
})

  const toggleShown = commandName => {
    const shownState = detailsShown.slice();
    const index = shownState.indexOf(commandName)
    if (index >= 0) {
      shownState.splice(index, 1);
    } else {
      shownState.push(commandName);
    }

    setDetailsShown(shownState)
  }


  if (commands) return (
    <Fragment>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
        @import url('https://fonts.googleapis.com/css?family=Pacifico');
        @import url('https://fonts.googleapis.com/css?family=Oswald');
        @import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
      </style>
      <title>Fusion Bot</title>
      <meta charSet="UTF-8" />
      <meta name="description" content="Um bot para discord, com moderação, utilizade, economia e muito mais!" />
      <meta name="keywords" content="JavaScript, Discord, Discord.js, Bot, Fusion" />
      <meta name="author" content="Felpopos" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="icon" href="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2FFusion.png?v=1591372725015" />
      <link rel='stylesheet' href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css" />

      <div class="commandsPage">
        <Navbar/>
        <Sidebar/>
        
        <div id="main">
          <div class="commandsList">
            {console.log(commands.filter(command => command.type == 'fun' && command.visible == true))}
            <h1>Comandos</h1>
            <br />
            <h4>&lt;&gt; - Argumentos obrigatórios</h4><h4>[] - Argumentos opcionais</h4>
            <h5>Não inclua isto ao enviar um comando</h5>
            <br/>
            <h5>Passe o mouse ou clique em um argumento para mais informações sobre ele</h5>
            <div class="commandsCategory">
              <h2 id='title'>Diversão</h2>
              <table>
                <tr>
                  <th>Comando</th>
                  <th>Argumentos</th>
                  <th>Descrição</th>
                </tr>
                <tbody>
                  {commands.filter(command => command.type == 'fun' && command.visible == true && command.data).map(command => {
                    return (
                      <Fragment>
                        <tr id="command">
                          <td id='commandName'>{command.data.options && command.data.options.find(option => option.type == 1 || option.type == 2) ? !detailsShown.includes(command.data.name) ? (<i class="fa-solid fa-plus" id="expandIcon" onClick={() => toggleShown(command.data.name)}></i>) : (<i class="fa-solid fa-minus" id="expandIcon" onClick={() => toggleShown(command.data.name)}></i>) : null} <text id="nameText">/{command.data.name}</text></td>
                          <td id='commandArgs'>{command.data.options && command.data.options.find(option => option.type > 2) ? command.data.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                          <td id='commandDescription'>{command.data.description}</td>
                        </tr>

                        {command.data.options && command.data.options.find(option => option.type == 1 || option.type == 2) && detailsShown.includes(command.data.name) ? (
                          <tr class="subcommands">
                            {command.data.options.filter(option => option.type == 2).map(subcommandGroup => {
                              return (
                                <Fragment>
                                  <tr id="subcommandGroup">
                                  <td id='commandName'>{subcommandGroup.options && subcommandGroup.options.find(option => option.type == 1) ? !detailsShown.includes(command.data.name + " " + subcommandGroup.name) ? (<i class="fa-solid fa-plus" id="expandIcon" onClick={() => toggleShown(command.data.name + " " + subcommandGroup.name)}></i>) : (<i class="fa-solid fa-minus" id="expandIcon" onClick={() => toggleShown(command.data.name + " " + subcommandGroup.name)}></i>) : null} <text id="nameText">/{command.data.name} {subcommandGroup.name}</text></td>
                                    <td id='commandArgs'></td>
                                    <td id='commandDescription'>{subcommandGroup.description}</td>
                                  </tr>

                                  {detailsShown.includes(command.data.name + " " + subcommandGroup.name) ? subcommandGroup.options.filter(option => option.type == 1).map(subcommand => {
                              return (
                                <Fragment>
                                  <tr id="subcommandInGroup">
                                    <td id='commandName'><text id="nameText">/{command.data.name} {subcommandGroup.name} {subcommand.name}</text></td>
                                    <td id='commandArgs'>{subcommand.options && subcommand.options.find(option => option.type > 2) ? subcommand.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                                    <td id='commandDescription'>{subcommand.description}</td>
                                  </tr>
                                </Fragment>
                              )
                            }) : ''}
                                </Fragment>
                              )
                            })}
                            {command.data.options.filter(option => option.type == 1).map(subcommand => {
                              return (
                                <Fragment>
                                  <tr id="subcommand">
                                  <td id='commandName'><text id="nameText">/{command.data.name} {subcommandGroup.name} {subcommand.name}</text></td>
                                    <td id='commandArgs'>{subcommand.options && subcommand.options.find(option => option.type > 2) ? subcommand.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                                    <td id='commandDescription'>{subcommand.description}</td>
                                  </tr>
                                </Fragment>
                              )
                            })}
                          </tr>

                        ) : null}
                      </Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>

            <div class="commandsCategory">
              <h2 id='title'>Moderação</h2>
              <table>
                <tr>
                  <th>Comando</th>
                  <th>Argumentos</th>
                  <th>Descrição</th>
                </tr>
                <tbody>
                  {commands.filter(command => command.type == 'moderation' && command.visible == true && command.data).map(command => {
                    return (
                      <Fragment>
                        <tr id="command">
                          <td id='commandName'>{command.data.options && command.data.options.find(option => option.type == 1 || option.type == 2) ? !detailsShown.includes(command.data.name) ? (<i class="fa-solid fa-plus" id="expandIcon" onClick={() => toggleShown(command.data.name)}></i>) : (<i class="fa-solid fa-minus" id="expandIcon" onClick={() => toggleShown(command.data.name)}></i>) : null} <text id="nameText">/{command.data.name}</text></td>
                          <td id='commandArgs'>{command.data.options && command.data.options.find(option => option.type > 2) ? command.data.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                          <td id='commandDescription'>{command.data.description}</td>
                        </tr>

                        {command.data.options && command.data.options.find(option => option.type == 1 || option.type == 2) && detailsShown.includes(command.data.name) ? (
                          <tr class="subcommands">
                            {command.data.options.filter(option => option.type == 2).map(subcommandGroup => {
                              return (
                                <Fragment>
                                  <tr id="subcommandGroup">
                                  <td id='commandName'>{subcommandGroup.options && subcommandGroup.options.find(option => option.type == 1) ? !detailsShown.includes(command.data.name + " " + subcommandGroup.name) ? (<i class="fa-solid fa-plus" id="expandIcon" onClick={() => toggleShown(command.data.name + " " + subcommandGroup.name)}></i>) : (<i class="fa-solid fa-minus" id="expandIcon" onClick={() => toggleShown(command.data.name + " " + subcommandGroup.name)}></i>) : null} <text id="nameText">/{command.data.name} {subcommandGroup.name}</text></td>
                                    <td id='commandArgs'></td>
                                    <td id='commandDescription'>{subcommandGroup.description}</td>
                                  </tr>

                                  {detailsShown.includes(command.data.name + " " + subcommandGroup.name) ? subcommandGroup.options.filter(option => option.type == 1).map(subcommand => {
                              return (
                                <Fragment>
                                  <tr id="subcommandInGroup">
                                    <td id='commandName'><text id="nameText">/{command.data.name} {subcommandGroup.name} {subcommand.name}</text></td>
                                    <td id='commandArgs'>{subcommand.options && subcommand.options.find(option => option.type > 2) ? subcommand.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                                    <td id='commandDescription'>{subcommand.description}</td>
                                  </tr>
                                </Fragment>
                              )
                            }) : ''}
                                </Fragment>
                              )
                            })}
                            {command.data.options.filter(option => option.type == 1).map(subcommand => {
                              return (
                                <Fragment>
                                  <tr id="subcommand">
                                  <td id='commandName'><text id="nameText">/{command.data.name} {subcommandGroup.name} {subcommand.name}</text></td>
                                    <td id='commandArgs'>{subcommand.options && subcommand.options.find(option => option.type > 2) ? subcommand.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                                    <td id='commandDescription'>{subcommand.description}</td>
                                  </tr>
                                </Fragment>
                              )
                            })}
                          </tr>

                        ) : null}
                      </Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>

            <div class="commandsCategory">
              <h2 id='title'>Economia</h2>
              <table>
                <tr>
                  <th>Comando</th>
                  <th>Argumentos</th>
                  <th>Descrição</th>
                </tr>
                <tbody>
                  {commands.filter(command => command.type == 'economy' && command.visible == true && command.data).map(command => {
                    return (
                      <Fragment>
                        <tr id="command">
                          <td id='commandName'>{command.data.options && command.data.options.find(option => option.type == 1 || option.type == 2) ? !detailsShown.includes(command.data.name) ? (<i class="fa-solid fa-plus" id="expandIcon" onClick={() => toggleShown(command.data.name)}></i>) : (<i class="fa-solid fa-minus" id="expandIcon" onClick={() => toggleShown(command.data.name)}></i>) : null} <text id="nameText">/{command.data.name}</text></td>
                          <td id='commandArgs'>{command.data.options && command.data.options.find(option => option.type > 2) ? command.data.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                          <td id='commandDescription'>{command.data.description}</td>
                        </tr>

                        {command.data.options && command.data.options.find(option => option.type == 1 || option.type == 2) && detailsShown.includes(command.data.name) ? (
                          <tr class="subcommands">
                            {command.data.options.filter(option => option.type == 2).map(subcommandGroup => {
                              return (
                                <Fragment>
                                  <tr id="subcommandGroup">
                                  <td id='commandName'>{subcommandGroup.options && subcommandGroup.options.find(option => option.type == 1) ? !detailsShown.includes(command.data.name + " " + subcommandGroup.name) ? (<i class="fa-solid fa-plus" id="expandIcon" onClick={() => toggleShown(command.data.name + " " + subcommandGroup.name)}></i>) : (<i class="fa-solid fa-minus" id="expandIcon" onClick={() => toggleShown(command.data.name + " " + subcommandGroup.name)}></i>) : null} <text id="nameText">/{command.data.name} {subcommandGroup.name}</text></td>
                                    <td id='commandArgs'></td>
                                    <td id='commandDescription'>{subcommandGroup.description}</td>
                                  </tr>

                                  {detailsShown.includes(command.data.name + " " + subcommandGroup.name) ? subcommandGroup.options.filter(option => option.type == 1).map(subcommand => {
                              return (
                                <Fragment>
                                  <tr id="subcommandInGroup">
                                    <td id='commandName'><text id="nameText">/{command.data.name} {subcommandGroup.name} {subcommand.name}</text></td>
                                    <td id='commandArgs'>{subcommand.options && subcommand.options.find(option => option.type > 2) ? subcommand.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                                    <td id='commandDescription'>{subcommand.description}</td>
                                  </tr>
                                </Fragment>
                              )
                            }) : ''}
                                </Fragment>
                              )
                            })}
                            {command.data.options.filter(option => option.type == 1).map(subcommand => {
                              return (
                                <Fragment>
                                  <tr id="subcommand">
                                  <td id='commandName'><text id="nameText">/{command.data.name} {subcommand.name}</text></td>
                                    <td id='commandArgs'>{subcommand.options && subcommand.options.find(option => option.type > 2) ? subcommand.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                                    <td id='commandDescription'>{subcommand.description}</td>
                                  </tr>
                                </Fragment>
                              )
                            })}
                          </tr>

                        ) : null}
                      </Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>

            {/* <div class="commandsCategory">
  <h2 id='title'>Nível</h2>
  <table>
      <tr>
        <th>Comando</th>
        <th>Argumentos</th>
        <th>Descrição</th>
        <th>Sinônimos</th>
      </tr>
      {commands.filter(command => command.type == 'level' && command.visible == true && command.description).map(command => {
        return (<tr id="command">
          <td id='commandName'>&lt;{command.name}</td>
          <td id='commandArgs'>{command.args ? command.args['pt-br'].replace(/`/g, ""): ''}</td>
          <td id='commandDescription'>{command.description['pt-br']}</td>
          <td id='commandAliases'>{command.aliases.join(', ')}</td>
        </tr>)
      })}
  </table>
  </div> */}

            <div class="commandsCategory">
              <h2 id='title'>Música</h2>
              <table>
                <tr>
                  <th>Comando</th>
                  <th>Argumentos</th>
                  <th>Descrição</th>
                </tr>
                <tbody>
                  {commands.filter(command => command.type == 'music' && command.visible == true && command.data).map(command => {
                    return (
                      <Fragment>
                        <tr id="command">
                          <td id='commandName'>{command.data.options && command.data.options.find(option => option.type == 1 || option.type == 2) ? !detailsShown.includes(command.data.name) ? (<i class="fa-solid fa-plus" id="expandIcon" onClick={() => toggleShown(command.data.name)}></i>) : (<i class="fa-solid fa-minus" id="expandIcon" onClick={() => toggleShown(command.data.name)}></i>) : null} <text id="nameText">/{command.data.name}</text></td>
                          <td id='commandArgs'>{command.data.options && command.data.options.find(option => option.type > 2) ? command.data.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                          <td id='commandDescription'>{command.data.description}</td>
                        </tr>

                        {command.data.options && command.data.options.find(option => option.type == 1 || option.type == 2) && detailsShown.includes(command.data.name) ? (
                          <tr class="subcommands">
                            {command.data.options.filter(option => option.type == 2).map(subcommandGroup => {
                              return (
                                <Fragment>
                                  <tr id="subcommandGroup">
                                  <td id='commandName'>{subcommandGroup.options && subcommandGroup.options.find(option => option.type == 1) ? !detailsShown.includes(command.data.name + " " + subcommandGroup.name) ? (<i class="fa-solid fa-plus" id="expandIcon" onClick={() => toggleShown(command.data.name + " " + subcommandGroup.name)}></i>) : (<i class="fa-solid fa-minus" id="expandIcon" onClick={() => toggleShown(command.data.name + " " + subcommandGroup.name)}></i>) : null} <text id="nameText">/{command.data.name} {subcommandGroup.name}</text></td>
                                    <td id='commandArgs'></td>
                                    <td id='commandDescription'>{subcommandGroup.description}</td>
                                  </tr>

                                  {detailsShown.includes(command.data.name + " " + subcommandGroup.name) ? subcommandGroup.options.filter(option => option.type == 1).map(subcommand => {
                              return (
                                <Fragment>
                                  <tr id="subcommandInGroup">
                                    <td id='commandName'><text id="nameText">/{command.data.name} {subcommandGroup.name} {subcommand.name}</text></td>
                                    <td id='commandArgs'>{subcommand.options && subcommand.options.find(option => option.type > 2) ? subcommand.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                                    <td id='commandDescription'>{subcommand.description}</td>
                                  </tr>
                                </Fragment>
                              )
                            }) : ''}
                                </Fragment>
                              )
                            })}
                            {command.data.options.filter(option => option.type == 1).map(subcommand => {
                              return (
                                <Fragment>
                                  <tr id="subcommand">
                                  <td id='commandName'><text id="nameText">/{command.data.name} {subcommandGroup.name} {subcommand.name}</text></td>
                                    <td id='commandArgs'>{subcommand.options && subcommand.options.find(option => option.type > 2) ? subcommand.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                                    <td id='commandDescription'>{subcommand.description}</td>
                                  </tr>
                                </Fragment>
                              )
                            })}
                          </tr>

                        ) : null}
                      </Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>

            <div class="commandsCategory">
              <h2 id='title'>Utilidade</h2>
              <table>
                <tr>
                  <th>Comando</th>
                  <th>Argumentos</th>
                  <th>Descrição</th>
                </tr>
                <tbody>
                  {commands.filter(command => command.type == 'utility' && command.visible == true && command.data).map(command => {
                    return (
                      <Fragment>
                        <tr id="command">
                          <td id='commandName'>{command.data.options && command.data.options.find(option => option.type == 1 || option.type == 2) ? !detailsShown.includes(command.data.name) ? (<i class="fa-solid fa-plus" id="expandIcon" onClick={() => toggleShown(command.data.name)}></i>) : (<i class="fa-solid fa-minus" id="expandIcon" onClick={() => toggleShown(command.data.name)}></i>) : null} <text id="nameText">/{command.data.name}</text></td>
                          <td id='commandArgs'>{command.data.options && command.data.options.find(option => option.type > 2) ? command.data.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                          <td id='commandDescription'>{command.data.description}</td>
                        </tr>

                        {command.data.options && command.data.options.find(option => option.type == 1 || option.type == 2) && detailsShown.includes(command.data.name) ? (
                          <tr class="subcommands">
                            {command.data.options.filter(option => option.type == 2).map(subcommandGroup => {
                              return (
                                <Fragment>
                                  <tr id="subcommandGroup">
                                  <td id='commandName'>{subcommandGroup.options && subcommandGroup.options.find(option => option.type == 1) ? !detailsShown.includes(command.data.name + " " + subcommandGroup.name) ? (<i class="fa-solid fa-plus" id="expandIcon" onClick={() => toggleShown(command.data.name + " " + subcommandGroup.name)}></i>) : (<i class="fa-solid fa-minus" id="expandIcon" onClick={() => toggleShown(command.data.name + " " + subcommandGroup.name)}></i>) : null} <text id="nameText">/{command.data.name} {subcommandGroup.name}</text></td>
                                    <td id='commandArgs'></td>
                                    <td id='commandDescription'>{subcommandGroup.description}</td>
                                  </tr>

                                  {detailsShown.includes(command.data.name + " " + subcommandGroup.name) ? subcommandGroup.options.filter(option => option.type == 1).map(subcommand => {
                              return (
                                <Fragment>
                                  <tr id="subcommandInGroup">
                                    <td id='commandName'><text id="nameText">/{command.data.name} {subcommandGroup.name} {subcommand.name}</text></td>
                                    <td id='commandArgs'>{subcommand.options && subcommand.options.find(option => option.type > 2) ? subcommand.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                                    <td id='commandDescription'>{subcommand.description}</td>
                                  </tr>
                                </Fragment>
                              )
                            }) : ''}
                                </Fragment>
                              )
                            })}
                            {command.data.options.filter(option => option.type == 1).map(subcommand => {
                              return (
                                <Fragment>
                                  <tr id="subcommand">
                                  <td id='commandName'><text id="nameText">/{command.data.name} {subcommandGroup.name} {subcommand.name}</text></td>
                                    <td id='commandArgs'>{subcommand.options && subcommand.options.find(option => option.type > 2) ? subcommand.options.filter(option => option.type > 2).map(arg => {
                                      return (
                                        <Fragment>
                                          <text id="arg-text">
                                            {arg.required == true ? `<${arg.name}>` : `[${arg.name}]`}
                                          </text>

                                          <div id="arg-description">
                                            {
                                              arg.type == 3 ? "TEXTO" :
                                                arg.type == 4 ? "NÚMERO INTEIRO" :
                                                  arg.type == 5 ? "BOOLEAN" :
                                                    arg.type == 6 ? "USUÁRIO OU ID" :
                                                      arg.type == 7 ? "CANAL OU ID" :
                                                        arg.type == 8 ? "CARGO OU ID" :
                                                          arg.type == 9 ? "USUÁRIO OU CARGO" :
                                                            arg.type == 10 ? "NÚMERO" :
                                                              arg.type == 11 ? "ANEXO" : ""
                                            }
                                            <br />
                                            {arg.description}
                                            <br />
                                            <br />
                                            {arg.required == true ? `NECESSÁRIO` : `OPCIONAL`}
                                            <br />
                                          </div>
                                        </Fragment>)
                                    }) : ''}</td>
                                    <td id='commandDescription'>{subcommand.description}</td>
                                  </tr>
                                </Fragment>
                              )
                            })}
                          </tr>

                        ) : null}
                      </Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
  else return <div id="loading" />
}
const imgZoom = () => {
  var modal = document.getElementById("myModal");
  // Get the image and insert it inside the modal - use its "alt" text as a caption
  var img = document.getElementById("gifsetup");
  var modalImg = document.getElementById("img01");
  var captionText = document.getElementById("caption");
  modal.style.display = "block";
  modalImg.src = img.src;
  captionText.innerHTML = img.alt;
  // Get the <span> element that closes the modal
  var span = document.getElementsByClassName("close")[0];
  // When the user clicks on <span> (x), close the modal
  span.onclick = function () {
    modal.style.display = "none";
  }
}