import { useQuery } from '@apollo/client';
import { menuPageQuery } from './graphql/queries'
import './Navbar.css';

const Navbar = () => {
    const { loading, error, data } = useQuery(menuPageQuery)

    if (!loading) console.log(data)

    const login = () => window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord';

    function menuToggle() {
        const toggleMenu = document.querySelector(".accountMenu");
        toggleMenu.classList.toggle("active");
      }

      function logout() {
        window.localStorage.removeItem("token");
        location.reload()
      }

    return (
        <nav class="navigation-bar fixed">
            <link rel='stylesheet' href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css" />
          <div class="left-side-entries">
            <a href="/" style={{ fontFamily: 'Pacifico', paddingTop: 1 }}>Fusion</a>
            <a href="/dashboard" id="dashboard"><img src="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2Fgear.png?v=1595294274017" height={18} width={18} id="gear"></img> Dashboard</a>
            <a href="https://discord.gg/ryfTBmU" style={{ paddingTop: 5 }}><img src="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2Fdiscord.png?v=1595296304827" height={18} width={18} id="discord"></img> Suporte</a>
            <a href="/wiki/commands" id="commands" style={{ paddingTop: 5 }}><i class="fa-solid fa-terminal"></i> Comandos</a>
            {/*<a href="https://twitter.com/fusion_discord"><img src="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2Ftwitter.png?v=1599094463069" height={18} width={18} id="twitter"></img> Twitter</a>*/}
          </div>
          <div class="right-side-entries">
            {!loading && data && data.getUser && data.getUser.id ?
            <div className='userMenu'>
                <button onClick={menuToggle}>
                    <img src={`https://cdn.discordapp.com/avatars/${data.getUser.id}/${data.getUser.avatar}.png`} height={32} width={32} id="userImg" />
                    <text class="username" style={{ left: 2, bottom: 8, position: 'relative' }}>{data.getUser.username}</text>
                </button>
                <div class="accountMenu">
                <ul>
                  <li>
                    <img src="/icons/log-out.png" className='logout-button'/><a onClick={logout} className='logout-text'>Sair</a>
                  </li>
                </ul>
              </div>
            </div>
            : <text onClick={login} style={{ paddingTop: 4 }} class="login">Login</text>}
          </div>
        </nav>
    )
}

export default Navbar;