import { gql } from '@apollo/client';

export const menuPageQuery = gql`
  query getMenuPageData {
    getUser(token: \"${window.localStorage.token}\") {
      id
      username
      discriminator
      avatar
    }
  }
`;

export const profileInfoQuery = gql`
  query getProfileData {
    getUser(token: \"${window.localStorage.token}\") {
      id
      username
      discriminator
      avatar
      public_flags
      premium_type
    }
    getProfileInfo(token: \"${window.localStorage.token}\") {
      banner
      sobremim
      profileMethod
    }
  }
`;

export const dashboardPageQuery = gql`
    query getDashboardPageData {
      getUser(token:\"${window.localStorage.token}\") {
        id
        username
        discriminator
        avatar
      }
      getMutualGuilds(token:\"${window.localStorage.token}\"){
        included {
          name
          id
          icon
        }
        excluded {
          name
          id
          icon
        }
      }
    }
`;

export const configurePageQuery = gql`
    query getConfigurePageData($guildId: String!) {
        isInGuild(guildId: $guildId, token: \"${window.localStorage.token}\") {
          id
          name
        }
        getUser(token:\"${window.localStorage.token}\") {
            id
            username
            discriminator
            avatar
        }
        getGuildConfig(guildId: $guildId, token:\"${window.localStorage.token}\") {
            currency
            guildId
            defaultRole
            welcomeMessage
            welcomeChannel
            level
            music
            economy
            hardcore
        }
        getGuildRoles(guildId: $guildId, token:\"${window.localStorage.token}\") {
            id
            name
        }
        getGuildChannels(guildId: $guildId, token:\"${window.localStorage.token}\") {
            id
            name
        }
    }
`;