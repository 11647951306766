export function RedirectPage ({props, match}){
    var token = window.location.hash.split("=")[1];
    let paths = window.location.pathname.split("/")
    let redirectURL = null;
    if (paths.indexOf("redirect") < paths.length) {
      redirectURL = "";
      for (let i = 0; i < paths.length - (paths.indexOf("redirect") + 1); i++) {
        redirectURL += `/${paths[paths.indexOf("redirect") + 1 + i]}`
      }
    }
let url = redirectURL || 'dashboard';
     window.localStorage.token = token;
     window.location.href = `https://fusionbot.xyz/${url}`
  return null
}