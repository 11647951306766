import React, {Fragment} from 'react';
import './wiki.css';
import $ from 'jquery';
let pageInitialized = false

export function WikiMain (props){
  
  $(function(){
    if(pageInitialized) return;
    pageInitialized = true;
    console.log('test');
    $(".sub-btn").on("click", function(){
      console.log('test');
      $(this).next('.sub-menu').slideToggle();
      $(this).find('.dropdown').toggleClass('rotate');
    });

    $(".menu-btn").on("click", function() {
      $(".sidebar").addClass('active');
    })

    $(".close-btn").on("click", function() {
      $(".sidebar").removeClass('active');
    })
  });

    return (
      <div class="wiki">
        <Fragment>
        <style>
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
</style>
  <title>Fusion Bot</title>
  <meta charSet="UTF-8" />
  <meta name="description" content="Um bot para discord, com moderação, utilizade, economia e muito mais!" />
  <meta name="keywords" content="JavaScript, Discord, Discord.js, Bot, Fusion" />
  <meta name="author" content="Felpopos" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <link rel="icon" href="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2FFusion.png?v=1591372725015" />
  <link rel='stylesheet' href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"/>
  
  <div class="menu-btn">
    <i class="fas fa-bars"></i>
  </div>

  <div class="sidebar">
    <div class="close-btn">
      <i class="fas fa-times"></i>
    </div>
    <div class="menu">
      <div class="item"><a href="/wiki/commands">Comandos</a></div>
      <div class="item">
        <a class="sub-btn">Propriedades<i class="fas fa-angle-right dropdown"></i></a>
        <div class="sub-menu">
          <a href="/wiki/empresas" class="sub-item">Empresas</a>
          <a href="/wiki/transportadoras" class="sub-item">Transportadoras</a>
        </div>
      </div>
    </div>
  </div>
  <div class="text">
  </div>
</Fragment>
</div>
    )
}