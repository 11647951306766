import React, {Fragment} from 'react';
import { getUserDetails, getGuilds } from '../../utils/api.js'
import {MenuComponent} from '../../components'
import {useQuery} from '@apollo/client';
import { dashboardPageQuery } from '../../graphql/queries'
import './dashboard.css';
import Navbar from '../../Navbar.js';
export function DashboardPage(props) {
  
  const { loading, error, data } = useQuery(dashboardPageQuery)
  //const [{ data, loading, error }, refetch] = useAxios('https://api-fusiondiscordbot.glitch.me/api/auth')
  const login = () => window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord';   
  //console.log(getUserDetails())
  
  if (error) window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord'
    if (!loading && !error) {
      if (!data.getUser || !data.getMutualGuilds) window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord'
      const {getMutualGuilds} = data
      console.log(data)
      return (
        <Fragment>
          <style>
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
</style>
	<link rel='stylesheet' href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css" />
        <body class="DashboardPage">
        <Navbar/>
        <div class="main">
          <MenuComponent guilds={getMutualGuilds} />
        </div>
        </body>
        </Fragment>
      )
    } return <div class="DashboardPageLoading">
        <div id="loading" />   
        <text id="timeText">Isto pode demorar um pouco</text>
    </div>
  
    // xhr.send();
    // }, [])
    // return !loading && (
    //   <div>
    //     <h1>Dashboard Page</h1>
    //     <MenuComponent guilds={guilds} />
    //   </div>
    // )
}