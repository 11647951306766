import React, {Fragment, useEffect} from 'react';
import './transportadoras.css';
import Navbar from '../../../Navbar';
import Sidebar from '../../../Sidebar';

export function WikiTransportadoras (props){
  useEffect(() => {
    document.querySelectorAll('.highlight').forEach(item => item.addEventListener('click' , (button)=> {
      navigator.clipboard.writeText(button.target.innerHTML).then(
        function() {
          window.alert('Comando copiado!') 
        },
        function() {
          window.alert('Ops! Não foi possível copiar o comando :(')
        }
      )
    }))
  })

    return (
        <Fragment>
        <style>
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
</style>
  <title>Fusion Bot</title>
  <meta charset="UTF-8" />
  <meta name="description" content="Um bot para discord, com moderação, utilizade, economia e muito mais!" />
  <meta name="keywords" content="JavaScript, Discord, Discord.js, Bot, Fusion" />
  <meta name="author" content="Felpopos" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <link rel="icon" href="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2FFusion.png?v=1591372725015" />
  <link rel='stylesheet' href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"/>
<div class="transportadoras">
<Navbar/>
<Sidebar/>

  <div class="text">
  <h1>Transportadoras</h1>
  <p>As transportadoras são um tipo de propriedade que podem ser compradas na loja por 2.000.000 de dinheiro e tem quantidade limitadas.
    No Fusion, por enquanto, são 20 transportadoras compráveis.
  </p>
  <p>
    As transportadoras são propriedades globais, ou seja, se você comprar uma, valerá para todos os servidores.
  </p>
  
  <p>Ao comprar uma transportadora, você poderá digitar <a class="highlight">/transportadora info</a> para ver o painel de sua transportadora, lá você pode ver as informações dela e mexer nas configurações dela também. No painel da transportadora, você poderá ver o dinheiro no cofre dela, que é diferente do dinheiro pessoal, além de que você não pode colocar dinheiro no cofre da sua transportadora, é totalmente separado. Tem também a lista de funcionários da sua transportadora, para contratar um funcionário você precisa digitar <a class="highlight">/transportadora contratar funcionario</a> e mencionar o usuário, se ele aceitar, ele será oficialmente um funcionário de sua transportadora.</p>
  <h2>Lucros</h2>
<p>Existem duas maneiras de sua transportadora ganhar dinheiro:</p>

<p>A primeira é por hora, sua transportadora ganhará 500 x o nível dela por hora.</p>
<p>A segunda é por entrega, sua transportadora começa recebendo 5000 e ganha mais 3000 a cada nível.</p>

<p>Para carregar uma mercadoria, você ou seu funcionário precisará digitar <a class="highlight">/transportadora carregar</a>, esse comando só pode ser digitado de hora em hora.</p>
<h2>Tabela de lucros por hora (Baseado em uma transportadora de 2.000.000)</h2>
<div class="center">
  <p class="tabela">
  <span>Level da transportadora&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lucro por hora&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lucro por dia</span>
  <br/>
  <div id="values">
  <span>Level 01&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$500&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$12000</span>
  <span>Level 02&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$1000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$24000</span>
  <span>Level 03&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$1500&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$36000</span>
  <span>Level 04&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$2000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$48000</span>
  <span>Level 05&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$2500&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$60000</span>
  <span>Level 06&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$3000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$72000</span>
  <span>Level 07&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$3500&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$84000</span>
  <span>Level 08&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$4000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$96000</span>
  <span>Level 09&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$4500&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$108000</span>
  <span>Level 10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$5000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$120000</span>
  </div>
</p>
</div>

<h2>Tabela de lucros por entrega (Baseado em uma transportadora de 2.000.000)</h2>
<div class="center">
  <p class="tabela">
  <span>Level da transportadora&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lucro por entrega</span>
  <br/>
  <div id="values">
  <span>Level 01&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$5000</span>
  <span>Level 02&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$8000</span>
  <span>Level 03&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$11000</span>
  <span>Level 04&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$14000</span>
  <span>Level 05&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$17000</span>
  <span>Level 06&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$20000</span>
  <span>Level 07&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$23000</span>
  <span>Level 08&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$26000</span>
  <span>Level 09&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$29000</span>
  <span>Level 10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$32000</span>
  </div>
</p>
</div>

<h2>Funcionários</h2>
<p>
<ul>
  <li>As Transportadoras tem o limite de até 6 (seis) funcionários ou 10 (dez) se ela for gold.</li>
  <br/>
  <li>Para contratar um funcionário você precisa digitar <a class="highlight">/transportadora contratar funcionario</a> e mencionar o usuário, se ele aceitar, ele será oficialmente um funcionário de sua transportadora.</li>
  <br/>
  <li>Para demitir um funcionário, você terá digitar <a class="highlight">/transportadora info</a> e ver a vaga que o funcionário corresponde. Após isso você terá que digitar <a class="highlight">/transportadora demitir funcionario</a></li>
  <br/>
  <li>O salário dos funcionários será descontado do seu lucro por entrega.</li>
</ul>
</p>

<h2>Gerente</h2>
<p>
<ul>
  <li>O gerente é útil para te ajudar a administrar a transportadora, além de que ele recebe 1,25x mais de salário ao fazer uma entrega.</li>
  <br/>
  <li>Para contratar um gerente você precisa digitar <a class="highlight">/transportadora contratar gerente</a> e mencionar o usuário, se ele aceitar, ele será oficialmente um gerente de sua transportadora.</li>
  <br/>
  <li>Para demitir um gerente, é só digitar <a class="highlight">/transportadora demitir gerente</a> que o gerente será demitido</li>
  <br/>
  <li>O salário extra do gerente também é descontado do seu lucro por entrega.</li>
</ul>
</p>

<h2>Nível</h2>
<p>
  <ul>
    <li>É necessário de 200 entregas por nível para aumentar o nível de sua transportadora, quanto maior o nível, mais dinheiro você receberá.</li>
    <br/>
    <li>O nível máximo de uma transportadora é 5 (cinco)</li>
    <br/>
    <li>Se a transportadora for gold, o nível máximo dela será 10 (dez)</li>
  </ul>
</p>
<h2>Impostos</h2>
<p>
  <ul>
    <li>Todo dia às 00h00m a transportadora acumula 1 (um) imposto, após acumular 11 impostos ela é liberada automaticamente. (sem receber o dinheiro de volta)</li>
    <br/>
    <li>Para pagar os impostos, digite <a class="highlight">/transportadora pagarimpostos</a></li>
    <br/>
    <li>1 (um) imposto custa $15000, se a transportadora for gold, o imposto passará a custar $5000</li>
    <br/>
    <li>Com o sistema de metas, sua transportadora precisa fazer pelo menos 1 entrega por dia ou senão ela receberá 2 impostos. Vale lembrar que a quantidade de entregas para a meta acumula</li>
    <br/>
    <li>O preço dos impostos serão descontados do cofre da transportadora.</li>
  </ul>
</p>
  </div>
</div>
</Fragment>
    )
}