import React from 'react';
import axios from 'axios';
import './buy.css';
import { menuPageQuery } from '../../graphql/queries'
import {useQuery} from '@apollo/client';
export function Buy0001({
  history,
  match
}) {
    /*let Root = `https://${document.location.hostname}`;
    axios({
        method: 'POST',
        url: `${Root}/api/Controllers/DirectPaymentController.php`,
        headers: { 'content-type': 'application/json' }
      })
        .then(result => {
          console.log(result)
          if (result.data.search(/Warning/i) == -1 && result.data.search(/Fatal/i) == -1 && result.data != "") {
            setTimeout(function() {
              window.location.href = `https://pagseguro.uol.com.br/v2/checkout/payment.html?code=${result.data}`
            }, 500)
          }
          else window.location.reload();
        })

  return <body class="buy">
      <div id="loading"/>;
  </body>*/

  //let Root = `https://${document.location.hostname}`;

  const { loading, error, data } = useQuery(menuPageQuery)
  
  if (error) window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord'
  if (!loading && !error) {
    if (!data.getUser) window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord'
  console.log(data)

  let Root = `https://${document.location.hostname}`;
    axios({
        method: 'POST',
        url: `${Root}/api/buy?id=001&userId=${data.getUser.id}`,
        headers: { 'content-type': 'application/json' }
      })
        .then(result => {
          if (result?.data?.url) window.location.href = result.data.url;
        })
      }

  return <body className="buy">
      <div id="loading"/>
  </body>
}