import React from 'react';
import { Formik } from 'formik';
import '../DashboardMenu/style.css';
let blocked = false;
export function DashboardMenu({
  history,
  guildInfo,
  user,
  roles,
  channels,
  config,
  updateCurrency,
  updateLevel,
  updateMusic,
  updateEconomy,
  updateRole,
  updateWelcome
}) {
  const defaultRoleId = config.defaultRole ? config.defaultRole : '';
  const configWelcomeMessage = config.welcomeMessage ? config.welcomeMessage : 'Nenhuma';
  const configWelcomeChannelId = config.welcomeChannel;
  const handleKeyDown = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  return (
  <div class="options">
      <div class="switchs">
      {/* <text class="config-title">Módulos do Fusion</text> */}
      {/* <Formik>
      {
        (props) => (
          <form>
            <text class="config-title">Nível</text>
            <label class="switch">
              <input type="checkbox" name="level" onChange={(level) => updateLevel(level.target.checked)} defaultChecked={config.level}/>
                <span class="slider round" />
            </label>
          </form>
        )
      }
      </Formik> */}
      <Formik>
      {
        (props) => (
          <form>
            <text class="config-title">Música</text>
            <label class="switch">
              <input type="checkbox" name="music" onChange={(music) => updateMusic(music.target.checked)} defaultChecked={config.music}/>
                <span class="slider round" />
            </label>
          </form>
        )
      }
      </Formik>
      <Formik>
      {
        (props) => (
          <form>
            <text class="config-title">Economia</text>
            <label class="switch">
              <input type="checkbox" name="economy" onChange={(economy) => updateEconomy(economy.target.checked)} defaultChecked={config.economy}/>
                <span class="slider round" />
            </label>
          </form>
        )
      }
      </Formik>
      </div>
      <div class="currency">
    <Formik
    initialValues={{currency: config.currency}}
    onSubmit={async ({currency}) => {
      if (blocked == true) return
      console.log(currency)
      blocked = true;
      document.getElementsByClassName('dashboard-main')[0].style = 'cursor: progress;';
      document.getElementById('button').style = 'opacity: 0.6; cursor: not-allowed;';
        let response = await updateCurrency(currency);
        if (response != 200) document.getElementsByClassName('confirm-save')[0].innerHTML = 'Ops! Algum erro ocorreu :(',
        document.getElementsByClassName('confirm-save')[0].style = 'color: red; visibility: visible;'
        else document.getElementsByClassName('confirm-save')[0].style = 'visibility: visible;'
        document.getElementsByClassName('dashboard-main')[0].style = 'cursor: default;';
        setTimeout(function () {
          blocked = false;
          document.getElementsByClassName('confirm-save')[0].style = 'visibility: hidden;'
          document.getElementsByClassName('confirm-save')[0].innerHTML = 'Salvo com sucesso!',
        document.getElementById('button').style = 'opacity: 1; cursor: pointer;';
        }, 3000)
      }}>
      {
        (props) => (
          <form onSubmit={props.handleSubmit}>
            <text class="config-title">Moeda do Fusion</text>
            <text class="text">Por padrão, a moeda da economia do Fusion é 💵, mas você pode alterá-la abaixo.</text>
            <input type="text" name="currency" class="currencyBox" onChange={props.handleChange} defaultValue={config.currency} autoComplete="off" required={true} onKeyDown={handleKeyDown}/>
            <button type="submit" children="Salvar" id="button"/>
            <text class='confirm-save' style={{visibility: 'hidden'}}>Salvo com sucesso!</text>
          </form>
          )
}
    </Formik>
    </div>
      <div class="roleconfig">
      <Formik
        initialValues={{defaultRole: defaultRoleId}}
        onSubmit={async (defaultRole) => {
          if (blocked == true) return
      blocked = true;
      document.getElementsByClassName('dashboard-main')[0].style = 'cursor: progress;';
      document.getElementById('rolebutton').style = 'opacity: 0.6; cursor: not-allowed;';
        let response = await updateRole(defaultRole);
        if (response != 200) document.getElementsByClassName('roleconfirm-save')[0].innerHTML = 'Ops! Algum erro ocorreu :(',
        document.getElementsByClassName('roleconfirm-save')[0].style = 'color: red; visibility: visible;'
        else document.getElementsByClassName('roleconfirm-save')[0].style = 'visibility: visible;'
        document.getElementsByClassName('dashboard-main')[0].style = 'cursor: default;';
        setTimeout(function () {
          blocked = false;
          document.getElementsByClassName('roleconfirm-save')[0].style = 'visibility: hidden;'
          document.getElementsByClassName('roleconfirm-save')[0].innerHTML = 'Salvo com sucesso!',
        document.getElementById('rolebutton').style = 'opacity: 1; cursor: pointer;';
        }, 3000)
        }}
      >
      {
          (props) => (
            <form onSubmit={props.handleSubmit}>
              <text class="config-title">Cargo automático</text>
              <text class="text">Você pode fazer com que o Fusion dê um cargo para cada usuário que entrar no servidor na opção abaixo.</text>
              <select name="defaultRole" onChange={props.handleChange}>
                {roles.map((role) => (
                  (
                    <option value={role.id} selected={role.id === defaultRoleId}>{role.name}</option>
                  )
                ))}
              </select>
              <button type="submit" children="Salvar" id="rolebutton"/>
              <text class='roleconfirm-save' style={{visibility: 'hidden'}}>Salvo com sucesso!</text>
            </form>
          )
        }
      </Formik>
      </div>
      <div class="welcomeMessageConfig">
      <Formik
        initialValues={{welcomeMessage: configWelcomeMessage, welcomeChannel: configWelcomeChannelId}}
        onSubmit={async (welcomeMessage) => {
          if (blocked == true) return
      blocked = true;
      document.getElementsByClassName('dashboard-main')[0].style = 'cursor: progress;';
      document.getElementById('welcomeMessageButton').style = 'opacity: 0.6; cursor: not-allowed;';
        let response = await updateWelcome(welcomeMessage);
        if (response != 200) document.getElementsByClassName('welcomeMessageConfirm-save')[0].innerHTML = 'Ops! Algum erro ocorreu :(',
        document.getElementsByClassName('welcomeMessageConfirm-save')[0].style = 'color: red; visibility: visible;'
        else document.getElementsByClassName('welcomeMessageConfirm-save')[0].style = 'visibility: visible;'
        document.getElementsByClassName('dashboard-main')[0].style = 'cursor: default;';
        setTimeout(function () {
          blocked = false;
          document.getElementsByClassName('welcomeMessageConfirm-save')[0].style = 'visibility: hidden;'
          document.getElementsByClassName('welcomeMessageConfirm-save')[0].innerHTML = 'Salvo com sucesso!',
        document.getElementById('welcomeMessageButton').style = 'opacity: 1; cursor: pointer;';
        }, 3000)
        }}
      >
      {
         (props) => (
           <form onSubmit={props.handleSubmit}>
             <text class="config-title">Mensagem de boas-vindas</text>
             <text class="text">Você pode definir uma mensagem para ser enviada quando alguém entrar no servidor.</text>
             <input type="text" name="welcomeMessage" class="welcomeMessageInput" onChange={props.handleChange} defaultValue={configWelcomeMessage}/>
             <select name="welcomeChannel" class="welcomeMessageSelect" onChange={props.handleChange}>
               {channels.map((channel) => (
                 <option value={channel.id} selected={channel.id === configWelcomeChannelId}>{channel.name}</option>
               ))}
             </select>
             <button type="submit" children="Salvar" id="welcomeMessageButton"></button>
             <text class='welcomeMessageConfirm-save' style={{visibility: 'hidden'}}>Salvo com sucesso!</text>
           </form>
         ) 
      }
      </Formik>
      </div>
    </div>
  )
}