import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { menuPageQuery } from '../../graphql/queries'
import './menu.css';
import Navbar from '../../Navbar';
export function MenuPage(props) {
  const { loading, error, data } = useQuery(menuPageQuery)

  if (!loading) console.log(data)

  const login = () => window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord';
  return (
    <Fragment>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
        @import url('https://fonts.googleapis.com/css?family=Pacifico');
        @import url('https://fonts.googleapis.com/css?family=Oswald');
        @import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
      </style>
      <link rel='stylesheet' href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css" />
      <body class="MenuPage">
        <Navbar/>
        <div id="main">
          <div id="top">
            <aside id="content">
              <br />
              <img src="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2FFusion.png?v=1570024538143" width="150" height="150" id="fusion" style={{ float: 'left', paddingRight: 10 }} hidden={true} />
              <br />
              <br />
              <br />
              <br />
              <br />
              <text class="title">Fusion</text>
              <div id="butoes">
                <a href="https://top.gg/bot/606312049798152204/invite"><button class="grow">Adicionar bot</button></a>
              </div>
            </aside>
            <aside id="direita">
              <p style={{ fontWeight: 'bold', fontFamily: ('Exo 2', 'sans-serif'), textAlign: 'center' }} class="texto">Uma maneira rápida e fácil de adminstrar seu servidor e deixá-lo mais divertido.</p>
            </aside>
            {/*<div class="bouncy-arrow">⇩</div>*/}
            
            <i className="fa-solid fa-chevron-down"></i>
          </div>
          {/*<!--<a href="https://fusionbot.xyz/ferraz.html">▫</a>!-->*/}
          <div id="menu-2">
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
            {/*<!--
<!--Display 1
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-4680757769600220"
     data-ad-slot="1259776081"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>
    !-->*/}
            <div class="about-me">
              <h1 style={{ lineHeight: 1.58, fontWeight: 500, fontFamily: 'Bree Serif', color: 'white', textShadow: (0, 0, 0, 'white') }}>
                Mas, o que é o <div class="shine" style={{ position: 'relative', left: 6 }}>Fusion</div><text style={{ color: 'white', position: 'relative', left: 4, textShadow: (0, 0, 0, 'white') }}>?</text>
              </h1>
              <p style={{ position: 'relative', top: 10 }}>Com mais de 100 comandos, o Fusion é um bot do discord focado em economia e com várias funções diferenciadas para administrar e tornar seu servidor um local mais divertido!</p>
              <div id="left">
                <img src="https://i.imgur.com/kVcBGW5.png"></img>
                <div id="text">
                  <h2 style={{ lineHeight: 1.5, fontWeight: 100, fontFamily: 'Bree Serif', color: 'white' }}>Engajando seu servidor!</h2>
                  <p>O Fusion pode ajudar à engajar seu servidor com seu sistema de experiência. Você pode dar cargos como recompensa para as pessoas que mandarem mensagem em seu servidor.</p>
                </div>
              </div>
            </div>
            <div class="how-to-use">
              <h1 style={{ lineHeight: 1.58, fontWeight: 500, fontFamily: 'Bree Serif', color: 'white', textShadow: (0, 0, 0, 'rgba(205, 205, 205, 0.5)') }}>
                Como usar?
              </h1>
              <p style={{ position: 'relative', top: 3 }}>Usar o Fusion é bem simples, você pode a qualquer momento pedir uma ajudinha com o comando ajuda, mas aqui vai um jeito mais resumido de como usar o bot. A maioria dos servidores usa o Fusion para a economia, se você quiser começar na economia o primeiro comando que você terá que digitar é setup</p>
              <img id="gifsetup" onClick={imgZoom} src="https://cdn.glitch.com/218dc83e-e342-4c4e-b830-7aaa522e0e36%2Fgif%20setup.gif?v=1599849569899" />

              <div id="myModal" class="modal">
                {/*<!-- The Close Button -->*/}
                <span class="close">&times;</span>
                {/*<!-- Modal Content (The Image) -->*/}
                <img class="modal-content" id="img01" />
                {/*<!-- Modal Caption (Image Text) -->*/}
                <div id="caption"></div>
              </div>
            </div>
            <h1>E tudo isso, totalmente de graça!</h1>
            <p>Está esperando o quê? <a href="https://top.gg/bot/606312049798152204/invite">Adicione o Fusion no seu servidor!</a></p>
          </div>
        </div>
      </body>
    </Fragment>
  )
}
const imgZoom = () => {
  var modal = document.getElementById("myModal");
  // Get the image and insert it inside the modal - use its "alt" text as a caption
  var img = document.getElementById("gifsetup");
  var modalImg = document.getElementById("img01");
  var captionText = document.getElementById("caption");
  modal.style.display = "block";
  modalImg.src = img.src;
  captionText.innerHTML = img.alt;
  // Get the <span> element that closes the modal
  var span = document.getElementsByClassName("close")[0];
  // When the user clicks on <span> (x), close the modal
  span.onclick = function () {
    modal.style.display = "none";
  }
}