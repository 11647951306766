import React from 'react';
import { useQuery } from '@apollo/client';
import { menuPageQuery } from '../../graphql/queries'
import './coins.css';
import Navbar from '../../Navbar';

export function CoinsPage (props){
    const { loading, error, data } = useQuery(menuPageQuery)

    if (!loading) console.log(data)

    const login = () => window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord';

    function menuToggle() {
        const toggleMenu = document.querySelector(".menu");
        toggleMenu.classList.toggle("active");
      }

      function logout() {
        window.localStorage.removeItem("token");
        location.reload()
      }

      if (error) window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord?url=setup'
    if (!loading && !error) {
      if (!data || !data.getUser) window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord?url=setup'
    return (
        <div>
        <head>
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet"></link>
        </head>
            <body class="CoinsPage">
            <Navbar/>
                <div class="main">
                    <div class="text">
                    <h1>Comprar Purple Coins</h1>
                    <hr/>
                    <h2>O que são as Purple Coins?</h2>
                    <p>As Purple Coins são um tipo de moeda rara no Fusion utilizada para comprar itens especiais, a única forma de obtê-las é comprando ou a partir da ascensão.</p>
                    <h2>Benefícios de comprar as Purple Coins</h2>
                    <p>As Purple Coins são utlizadas para comprar itens nas lojas, melhorar propriedades e entre outros. Quando você compra Purple Coins, você recebe uma medalha na /identidade e o tempo que você precisa esperar para digitar comandos é reduzido para 1 segundo. Além disso, você ajuda a manter o Fusion ativo, já que essa é a única forma de fazer uma doação! 💜</p>
                    <h2>Como funciona a compra?</h2>
                    <p>Ao escolher comprar as Purple Coins, seu pagamento será processado pela <a href='https://stripe.com/'>Stripe</a>, uma empresa especializada em processamento de pagamentos. Após finalizar o pagamento, você irá receber as Purple Coins automaticamente.</p>
                    <h2>O que tenho que fazer depois que efetuar a compra?</h2>
<p>Nada! Assim que a compra for aprovada, você receberá suas Purple Coins.</p>
                    
                    <br/><br/><br/><p>Qualquer problema ou dúvida você pode me contatar pelo comando <text class="highlight">/whatsapp enviar 395928764644589578 &lt;mensagem&gt;</text> ou <a href="https://discord.gg/ryfTBmU">entrar</a> no servidor do Fusion</p><br/>
                    </div>
                    <div class="buySpace">
                      <div>
                        <h2>Comprar 100 Purple Coins por R$7,90 para {data.getUser.username}</h2>
                        <button class="purchaseButton" onClick={() => window.location.href='/buy/0001'}>Comprar usando <img src="/icons/stripe-logo.png" id="stripe-logo"></img></button>
                        </div>
              <div>
                        <h2>Comprar 200 Purple Coins por R$13,90 para {data.getUser.username}</h2>
                        <button class="purchaseButton" onClick={() => window.location.href='/buy/0002'}>Comprar usando <img src="/icons/stripe-logo.png" id="stripe-logo"></img></button>
                    </div>

                    </div>
                    
                </div>
            </body>
        </div>
    )
} else return <div id="loading"/>
};