import {React, useEffect} from 'react';
import Navbar from '../../../Navbar';
import './success.css'

export function Success(props) {
    let redirectSeconds = 10;
    useEffect(() => {
        window.setInterval(function () {
            redirectSeconds--;
            if (redirectSeconds <= 0) location.href = "/coins";
            document.getElementById("redirectText").innerHTML = `Redirecionando você em ${redirectSeconds} segundos`;
        }, 1000);
    })

    return (
        <div class="SuccessPage">
            <div id="checkbox">
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            <span class="confirmationText">Tudo certo! <br/>Obrigado pela sua compra, assim que seu pagamento for confirmado você receberá suas Purple Coins! 💜</span>
            <span class="confirmationText" id="redirectText">Redirecionando você em {redirectSeconds} segundos</span>
            </div>
        </div>
    )
};