import React from 'react';
import './profile.css';
import jimp from 'jimp';
import { useQuery, useMutation } from '@apollo/client';
import { profileInfoQuery } from '../../graphql/queries'
import { updateProfileBannerMutation, updateProfileAboutMeMutation, updateProfileMethodMutation } from '../../graphql/mutations'
export function ProfilePage(props) {
  const { loading, error, data } = useQuery(profileInfoQuery)
  const [updateProfileBanner] = useMutation(updateProfileBannerMutation)
  const [updateProfileAboutMe] = useMutation(updateProfileAboutMeMutation)
  const [updateProfileMethod] = useMutation(updateProfileMethodMutation)

  const imagRef = React.createRef();

  if (error) return window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord?url=profile'

  if (!loading && data) {
    if (!data.getUser) return window.location.href = 'https://api-fusiondiscordbot.glitch.me/api/auth/discord?url=profile';
    let banner = data.getProfileInfo.banner;
    let sobremim = data.getProfileInfo.sobremim;
    let profileType = data.getProfileInfo.profileMethod;
    generateProfileImage();
    async function generateProfileImage() {
      let fonte = await jimp.loadFont('/fonts/OpenSans-Regular 24.fnt')
      let fontenome = await jimp.loadFont('/fonts/OpenSans-Regular 28.fnt')
      let mask = await jimp.read('/mascara.png')
      let fundo = await jimp.read('/fundoprofile.png');
      let bannerimage;

      try {
        bannerimage = await jimp.read("https://corsproxy.io/?" + banner)
      } catch (e) { }

      if (bannerimage) {
        var ratio = 736 / bannerimage.getWidth() >= 405 / bannerimage.getHeight() ? 736 / bannerimage.getWidth() : 405 / bannerimage.getHeight();

        if (!profileType || profileType == "Fit") {
          bannerimage.resize(736, 405)
          fundo.composite(bannerimage, 0, 110)
        }
        else if (profileType == "Crop") {
          bannerimage.scale(ratio, function (err, image) {
            image.crop((image.getWidth() - 736) / 2, (image.getHeight() - 405) / 2, 736, 405)
            fundo.composite(image, 0, 110)
          });
        } else if (profileType == "Resize") {
          bannerimage.scale(ratio, function (err, bannerimage) {
            fundo.resize(bannerimage.getWidth(), bannerimage.getHeight() > 1000 ? 1000 : bannerimage.getHeight() + 274);
            bannerimage.crop(0, (bannerimage.getHeight() / 2) - (fundo.getHeight() - (281 * (fundo.getHeight() / 686))) / 2, fundo.getWidth(), fundo.getHeight() - (281 * (fundo.getHeight() / 686)))
            fundo.composite(bannerimage, 0, 110 * (fundo.getHeight() / 686))
          });
        }
      }
      jimp.read(`https://cdn.discordapp.com/avatars/${data.getUser.id}/${data.getUser.avatar}.png`).then(async avatar => {
        avatar.resize(170, 170)
        mask.resize(170, 170)
        avatar.mask(mask)

        fundo.composite(avatar, 10, fundo.getHeight() - 255 - ((fundo.getHeight() / 686) - 1) * 170)
        fundo.print(fontenome, 200, fundo.getHeight() - 160 - ((fundo.getHeight() / 686) - 1) * 170, `${data.getUser.username}#${data.getUser.discriminator}`)

        if (!sobremim) fundo.print(fonte, 200, fundo.getHeight() - 115 - ((fundo.getHeight() / 686) - 1) * 170, `Digite /sobremim, para colocar uma info sua em seu perfil`, 500)
        else fundo.print(fonte, 200, fundo.getHeight() - 115 - ((fundo.getHeight() / 686) - 1) * 170, sobremim, 500)

        let badgespacing = 10;

        const House_Bravery = 64;
        const House_Brilliance = 128;
        const House_Balance = 256;
        const Early_Supporter = 512;
        const Early_Verified_Bot_Developer = 131072;
        if ((data.getUser.public_flags & House_Bravery) == House_Bravery) {
          await jimp.read('https://cdn.glitch.global/a24d853e-e026-435a-952c-b188119683a1/house bravery.png').then(async badge => {
            badge.resize(30, 30)
            fundo.composite(badge, badgespacing, fundo.getHeight() - 40 - ((fundo.getHeight() / 686) - 1))
            badgespacing += 30;
          })
        }

        if ((data.getUser.public_flags & House_Brilliance) == House_Brilliance) {
          await jimp.read('https://cdn.glitch.global/a24d853e-e026-435a-952c-b188119683a1/house brilliance.png').then(async badge => {
            badge.resize(30, 30)
            fundo.composite(badge, badgespacing, fundo.getHeight() - 40 - ((fundo.getHeight() / 686) - 1))
            badgespacing += 30;
          })
        }

        if ((data.getUser.public_flags & House_Balance) == House_Balance) {
          await jimp.read('https://cdn.glitch.global/a24d853e-e026-435a-952c-b188119683a1/house balance.png').then(async badge => {
            badge.resize(30, 30)
            fundo.composite(badge, badgespacing, fundo.getHeight() - 40 - ((fundo.getHeight() / 686) - 1))
            badgespacing += 30;
          })
        }

        if ((data.getUser.public_flags & Early_Supporter) == Early_Supporter) {
          await jimp.read('https://cdn.glitch.global/a24d853e-e026-435a-952c-b188119683a1/early supporter.png?v=1666465094583').then(async badge => {
            badge.resize(30, 30)
            fundo.composite(badge, badgespacing, fundo.getHeight() - 40 - ((fundo.getHeight() / 686) - 1))
            badgespacing += 30;
          })
        }

        if ((data.getUser.public_flags & Early_Verified_Bot_Developer) == Early_Verified_Bot_Developer) {
          await jimp.read('https://cdn.glitch.global/a24d853e-e026-435a-952c-b188119683a1/bot developer badge.png?v=1666463946202').then(async badge => {
            badge.resize(30, 30)
            fundo.composite(badge, badgespacing, fundo.getHeight() - 40 - ((fundo.getHeight() / 686) - 1))
            badgespacing += 30;
          })
        }

        return fundo.getBase64(jimp.MIME_JPEG, function (err, src) {
          console.log(err)
          imagRef.current.src = src;
        });
      }).catch(err => {
        console.log(err)
      })
    }

    let onFileUpload = (event) => {
      jimp.read(`https://corsproxy.io/?${document.getElementById("bannerLink").value}`).then(async avatar => {
        console.log("Imagem válida!")
        updateProfileBanner({
          variables: {
            banner: document.getElementById("bannerLink").value
          }
        }).then(() => window.location.reload()).catch(() => window.location.reload())
      }).catch(err => {
        console.log("Não é uma imagem válida!")
        event.target.className="error";
        event.target.innerHTML="Imagem inválida!";

        setTimeout(async () => {
          event.target.className="";
          event.target.innerHTML="Salvar";
        }, 3000)
      })
    };

    let uploadAboutMe = async () => {
      console.log(document.getElementById("aboutMeText").value)
      updateProfileAboutMe({
        variables: {
          aboutMe: document.getElementById("aboutMeText").value
        }
      }).then(() => window.location.reload()).catch(() => window.location.reload())
    };

    let changeProfileMethod = async () => {
      console.log(document.getElementById("selectProfileMethod").value)
      updateProfileMethod({
        variables: {
          method: document.getElementById("selectProfileMethod").value
        }
      }).then(() => window.location.reload()).catch(() => window.location.reload())
    }

    return (
      <div className="ProfileImagePage">
        <img id='profileImage' ref={imagRef} />

        <div className='inputs'>
          <div id="bannerInput">
            <h2>Banner</h2>
            <input type="text" id="bannerLink" defaultValue={banner} />

            <button onClick={onFileUpload} id="bannerButton" class="">
              Salvar
            </button>
            {/*<h4>Salvo com sucesso!</h4>*/}
          </div>

          <div id="aboutMeInput">
            <h2>Sobre mim</h2>
            <input type="text" id="aboutMeText" defaultValue={sobremim} />

            <button onClick={uploadAboutMe}>
              Salvar
            </button>
          </div>

          <div id="profileMethodInput">
            <h2>Tipo de perfil</h2>
            <select id="selectProfileMethod" defaultValue={profileType || "Fit"}>
              <option value="Fit">Encaixar</option>
              <option value="Crop">Cortar</option>
              <option value="Resize">Redimensionar</option>
            </select>

            <button onClick={changeProfileMethod}>
              Salvar
            </button>
          </div>
        </div>
      </div>
    )
  } return <div class="DashboardPageLoading">
    <div id="loading" />   
    <text id="timeText">Isto pode demorar um pouco</text>
  </div>
};