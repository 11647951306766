import React from 'react';
import axios from 'axios';
import './index.css';
export function BuyConfirmation({
  history,
  match
}) {

  const [loading, setLoading] = React.useState(true);
  let [transactionData, setTransactionData] = React.useState(null);

    let queryString = window.location.href.split('?')[1].split('#')[0].split('&');

    if (loading == true) {
        axios({
            method: 'GET',
            url: `https://api-fusiondiscordbot.glitch.me/api/transaction/`,
            headers: {
                'content-type': 'application/json',
                'transactionCode': queryString.find(param => param.search('transaction_id') != -1).split('=')[1]
            }
        }).then(result => {
            setLoading(false);
            setTransactionData(result.data.transaction);
            console.log(result.data.transaction)
        })
    }

        if (loading == false && transactionData) return (
          <div id='div0001'>
              {transactionData.status[0] == '3' ?
              <div>
              {transactionData.reference[0] == "100COINS" ? <h1 style={{textAlign: 'center'}}>Obrigado por comprar suas 100 Purple Coins do Fusion! </h1>
              :
              <h1 style={{textAlign: 'center'}}> Obrigado por comprar suas Purple Coins do Fusion! </h1>}
              <div style={{textAlign: 'center'}}>Você já pode digitar <code class="inline">&lt;recebercoins {queryString.find(param => param.search('transaction_id') != -1).split('=')[1]}</code> no <strong>privado do Fusion</strong> para receber suas moedas!</div>
                  <div style={{textAlign: 'center', margin: 50}}><strong>ATENÇÃO!!</strong> Não exponha este código para ninguém, nem digite o comando em algum servidor, digite apenas no privado do Fusion!</div>
                  </div>
              :
              <div>
                  <h1 style={{textAlign: 'center'}}>Falta pouco para você conseguir obter suas Purple Coins do Fusion!</h1>
                  <div style={{textAlign: 'center'}}>Ainda não identificamos seu pagamento. Quando o pagamento for realizado, você poderá digitar <code class="inline">&lt;recebercoins {queryString.find(param => param.search('transaction_id') != -1).split('=')[1]}</code> no <strong>privado do Fusion</strong> para receber suas moedas!</div>
                  <div style={{textAlign: 'center', margin: 50}}><strong>ATENÇÃO!!</strong> Não exponha este código para ninguém, nem digite o comando em algum servidor, digite apenas no privado do Fusion!</div>
              </div>}
          </div>
        )
        else return <div id="loading"/>
}